/* Accessibility */

/* This class should be used to position "screen reader only" text offscreen per https://webaim.org/techniques/css/invisiblecontent/ */
.sr-only-text {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}
